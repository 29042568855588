@import '../../../../scss/theme-bootstrap';
$foundation-quiz: false !default;

/**
 * @file standalone/suppress_ecom.scss
 *
 * shared styles for the "suppress ecom for retailers" feature
 *
 */

body.is-retailer {
  .product__button--add-to-bag,
  .product-brief__add-to-bag .product__button,
  .product-full__bag-btn .product__button,
  .skincare-primer__story-bobbi__content .product__button,
  .egiftcard-cashstar__link,
  .favorites__recommended-product-add-to-cart,
  .product-info__add-to-bag,
  .product-info__actions .product-info__link,
  .my-lists__list-link--add-all,
  .product-item__add-to-cart,
  .add-to-bag,
  .add_to_cart_btn,
  .add_to_cart,
  .add-link,
  .add_button,
  .btn-add-to-bag,
  .smoosh-add-link,
  .shaded_add_link,
  .virtual_addtobag,
  .promo_bca_button,
  .add-to-cart input[type='submit'],
  .addtobag input[type='submit'],
  .addtobag__form input[type='submit'],
  .product-full__add-button,
  button[data-test-id='add-to-cart'] {
    visibility: hidden;
  }
  @if $foundation-quiz {
    .foundation-finder-result-page {
      .product-brief {
        pointer-events: none;
        &__bottom {
          display: none;
        }
      }
    }
  }
}
